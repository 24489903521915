//import { ButtonWhite } from "../../components/button-white";

import {
  Box,
  Container,
  SubText,
  SubTextLight,
  Title,
  Section,
} from "./styles";

export function About() {
  return (
    <Section id="about">
      <Container>
        <Box>
          <Title>
            Bem-vindo ao<br /> Grupo Partnership®
          </Title>
          <SubText>
            Projetado em 2009 e fundado em 2019 através de parcerias, o GRUPO PARTNERSHIP segue alinhado aos objetivos 
            desenvolvidos no âmbito da Convenção-Quadro das Nações Unidas sobre Mudança do Clima, prestando um 
            serviço de excelência focado na transformação digital e obcecado pelo sucesso do cliente e redução de 
            impactos ambientais.
            <br />
            <br />
          </SubText>
          <SubTextLight>
            “Entendemos a importância da prosperidade econômica independentemente da classe, e por outro lado, a certeza 
            de que, se não forem implementadas iniciativas à curto prazo em alguns anos, a crescente emissão dos gases 
            de efeito estufa poderá causar impactos significativos. É hora de agir!” 
          
            <br />
            <br />
            Jhonathan Santos – CEO do GRUPO PARTNERSHIP
          </SubTextLight>
          <div className="flex w-[100%] ml-[-2rem] md:ml-0">
          </div>
        </Box>
      </Container>
    </Section >
  );
}
