//import { ButtonWhite } from "../../components/button-white";
import {
  Container,
  Box,
  Title,
  SubText,
  SubTextLight,
  //ButtonContainer,
  Section,
} from "./styles";

import img1 from "../../assets/images/green.png";

export function Purpose() {
  return (
    <Section id="purpose">
      <Container>
        <Box>
          <Title>PROPÓSITO</Title>
          <SubText>
            Ser uma empresa reconhecida por proporcionar resultados positivos em pessoas e empresas 
            através da TRANSFORMAÇÃO DIGITAL em todas as regiões do Brasil até e tornar-se NET ZERO CARBON até 2032.
            
          </SubText>
          <Title>MISSÃO</Title>
          <SubTextLight>
            Conectar pessoas e empresas aos resultados desejados com responsabilidade social, 
            ambiental, priorizando a vida e a satisfação do cliente.
            <br />
            <br />
            #PartnershipMovement
            <br />
            <br />
          </SubTextLight>
        </Box >
        <img src={img1} alt="nft"  />
      </Container>
    </Section>
  );
}
