import { DiscordLogo, FacebookLogo, InstagramLogo, LinkedinLogo, TelegramLogo, TwitterLogo } from "phosphor-react";
// import { Banner } from "../../components/banner";
// import { LogoBanner } from "../../components/logo-banner";
// import { Facebook } from "../../Icons/Facebook";
//import { Discord} from "../../Icons/Discord";
// import { Instagram } from "../../Icons/Instagram";
// import LinkedIn from "../../Icons/LinkedIn";
// import Twitter from "../../Icons/Twitter";
import { redirectToLink } from "../../utils/redirect-to-link";

import {
  Bottom,
  Container,
  IconList,
  Left,
  Section,
} from "./styles";

export function Footer() {
  // const scrollTo = (id: string) => {
  //     const element = document.getElementById(id);

  //     element &&
  //       element.scrollIntoView({
  //         behavior: "smooth",
  //         block: "start",
  //         inline: "nearest",
  //       });
  // };

  return (
    <Section id="footer">
      {/* <Banner /> */}
      <Container>
        <Left>
          {/* <LogoBanner /> */}
          <IconList>
            <button onClick={() => redirectToLink("https://facebook.com/grupopartnership/")}>
              <FacebookLogo size={28} weight="fill" color="#ffff" />
            </button>
            <button onClick={() => redirectToLink("https://br.linkedin.com/company/grupopartnership")}>
              <LinkedinLogo size={28} weight="fill" color="#ffff" />
            </button>
            <button onClick={() => redirectToLink("https://instagram.com/grupopartnership/")}>
              <InstagramLogo size={28} weight="fill" color="#ffff"/>
            </button>
            <button onClick={() => redirectToLink("https://twitter.com/@GP_Partnership1")}>
              <TwitterLogo size={28} weight="fill" color="#ffff" />
            </button>
            <button onClick={() => redirectToLink("https://discord.com/")}>
            <DiscordLogo size={28} weight="fill" color="#ffff"/>
            </button>
            <button onClick={() => redirectToLink("https://t.me/GrupoPartnership")}>
            <TelegramLogo size={28} weight="fill" color="#ffff" />
            </button>
          </IconList>
        </Left>

      </Container>

      <Bottom>
      &copy;{new Date().getFullYear()} | Grupo Partnership® | Todos os Direitos
                Reservados
      </Bottom>

    </Section>
  );
}
