import Typewriter from "typewriter-effect";
import "./TypeWritter.module.css"

export function TypeWritter() {
  return (
    <>
        <div className="h-[5rem] lg:h-[0rem]">
          <Typewriter
            options={{
              autoStart: true,
              loop: true,
            }}
            onInit={(typewriter) => {
              typewriter
                .typeString(`<span class='text__1'>Serviços de Engenharia</span>`)
                .pauseFor(1500)
                .deleteAll()
                .typeString(
                  `<span class='text__2'>Consultoria</span>`
                )
                .pauseFor(1500)
                .deleteAll()
                .typeString(`<span class='text__3'>Energias Renováveis</span>`)
                .pauseFor(1500)
                .deleteAll()
                .start();
            }}
          />
        </div>
    </>
  );
}
