import { About } from "./components/about"
import { Footer } from "./components/footer"
import { Halo } from "./components/halo"
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./styles/GlobalStyles";
import { light } from "./styles/Theme";
import { Purpose } from "./components/purpose";



export function App() {
  return (
    <>
      <ThemeProvider theme={light}>
        <GlobalStyles />
        <Halo />
        <About />
        <Purpose />
        <Footer /> 

      </ThemeProvider>

    </>
    
  )
}

export default App
